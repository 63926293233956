.image-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25;
  max-width: "100%";
  max-height: "100%";
}

.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.image-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 10px 0;
}

.image-item {
  margin-right: 10px;
}

.image-item img {
  width: 200px;
}
.button {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #4287f5;
  color: white;
  cursor: pointer;
  max-height: auto;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.zoom-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
}

.zoom-box img {
  max-width: 85vw;
  max-height: 85vh;
  object-fit: contain;
}

.zoom-box .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: white;
  z-index: 1;
}

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: clip;
}

.scrolling-wrapper-flexbox .card {
  flex: 0 0 auto;
  margin-right: 6px;
}

.card {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 300px;
  height: 300px;
}

.scrolling-wrapper,
.scrolling-wrapper-flexbox {
  margin-bottom: 20px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}
/*
.scrolling-wrapper::-webkit-scrollbar,
.scrolling-wrapper-flexbox::-webkit-scrollbar {
  display: none;
}
*/
